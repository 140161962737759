<template>
  <div class="back">
    <el-link type="info" @click="backPage()">返回</el-link>
  </div>
</template>

<script>
  export default {
    name: "BackPage",
    methods: {
      backPage() {
        this.$router.back();
      }
    }
  }
</script>

<style scoped>
  .back {
    width: 95%;
    padding: 12px 20px;
    background: white;
  }
</style>
