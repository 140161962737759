<template>
  <div class="main">
    <back-page/>
    <div class="detail-box">
      <h2>{{text.title}}</h2>
      <span style="color: #9d9d9d">
        Time:  {{text.create_time}}
      </span>
      <div class="content" v-html="text.content"/>
      <!--      <div class="footer">{{text.creator_name}}</div>-->
    </div>
  </div>
</template>

<script>
  import { projectDocsDetail } from '@/api/lists'
  import BackPage from "@/components/BackPage/index";
  export default {
    name: "detail",
    components: { BackPage },
    data() {
      return {
        docs_id: '',
        text: {
          title: '',
          content: ''
        }
      }
    },
    created() {
      this.docs_id = this.$route.query.id
      this.init()
    },
    methods: {
      init() {
        projectDocsDetail({ id: this.docs_id, key: this.$route.query.key }).then(response => {
          this.text = response.data
        })
      }
    }
  }
</script>

<style scoped>
  .main {
    width: 95%;
    margin: 0 auto;
    box-shadow: 0 0 5px rgba(224, 224, 224, 0.6);
    padding: 12px 20px;
    background: white;
  }
  .detail-box {
    min-height: 500px;
    margin-top: 10px;
    padding: 20px;
    text-align: center;
    background: white;
    border-radius: 10px;
  }
  .content {
    width: 100%;
    padding: 20px;
    text-align: left;
    overflow: hidden;
    word-break: break-word;
  }
  .content>>>p {
    font-size: 15px;
    line-height: 25px;
  }
  /*使用深层级来设置img*/
  .content >>> img{
    width: 100%;
    object-fit: contain;
  }
  .footer {
    width: 100%;
    padding: 20px;
    text-align: right;
  }
</style>
